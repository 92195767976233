import React, { useRef } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"

const SimplePage = ({ data }) => {
  const [ref] = useRef()
  const page = data.wagtail.page
  let _ancestor = { children: [], button: {} }
  data?.wagtail?.page?.ancestors?.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    }
  })
  const { url } = page
  const splitting = url.split("/")
  const parentSlug = url.split("/")[splitting.length - 3]
  let result = data.wagtail.page.ancestors.map((item) => {
    if (item.children) {
      const result = item.children.filter((nitem) => nitem.slug === parentSlug)
      return result
    }
    return undefined
  }).filter((item) => item)
  result = result.filter((item) => item && item.length > 0)


  return (
    <Layout
      isContent={true}
      button={_ancestor.button}
      parent={"sport-safety"}
      siteTitle={page.title}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/sport-safety/"> Sport Safety</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/sport-safety/${parentSlug}`}>
                      {" "}
                      {result[0][0].title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="tab-content-area">
          <div className="container simple-page">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <h1>{page.title}</h1>
                    <p>{page.descriptionSimple}</p>
                    {page.feedImage && (
                      <div className="clib-area">
                        <div className="club-img full-width-image">
                          <img
                            src={data.wagtail.page.feedImage.src}
                            alt="swim"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="c-title-content mb-80"
                      ref={ref}
                      dangerouslySetInnerHTML={{
                        __html: data.wagtail.page.bodySafe,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
        )}
    </Layout>
  )
}

SimplePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: Int) {
    wagtail {
      page(id: $id) {
        ... on SimplePage {
          id
          bodySafe
          descriptionSimple
          feedImage {
            src
          }
          button {
            name
            text
            url
          }
          url
          ancestors {
            ... on SportSafetyPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
            ... on SimplePage {
              children {
                title
                slug
                ... on SimplePage {
                  isProtected
                  live
                }
              }
              button {
                text
                name
                url
              }
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default SimplePage
